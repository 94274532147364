import React from 'react'
import * as styles from './form.module.css'

const Register = (({ handleSubmit, handleUpdate, history }) => (
  <form
    className={styles.form}
    method="post"
    onSubmit={event => {
      handleSubmit(event)
    }}
  >
    <p className={styles[`form__instructions`]}>
      Passwords must follow the following requirements:
    </p>
    <ul>
      <li>Minimum Length of 8 Characters</li>
      <li>At least 1 number</li>
      <li>At least 1 special character</li>
      <li>At least 1 uppercase letter</li>
      <li>At least 1 lowercase letter</li>
    </ul>
    <label className={styles[`form__label`]}>
      Email
      <input
        className={styles[`form__input`]}
        type="text"
        name="username"
        onChange={handleUpdate}
      />
    </label>
    <label className={styles[`form__label`]}>
      Password
      <input
        className={styles[`form__input`]}
        type="password"
        name="password"
        onChange={handleUpdate}
      />
    </label>
    <label className={styles[`form__label`]}>
      Password
      <input
        className={styles[`form__input`]}
        type="password"
        name="passwordConfirm"
        onChange={handleUpdate}
      />
    </label>
    <input className={styles[`form__button`]} type="submit" value="Register" />
  </form>
))

export default Register
